import FroalaEditor from 'froala-editor'

export function fontPlugin(Editor: FroalaEditor) {
  Editor.PLUGINS.customFontSize = function (editor: FroalaEditor) {
    let $ = editor.$

    Object.assign(Editor.DEFAULTS, {
      fontSize: [],
      customFontSizeSelection: false,
      customFontSizeDefaultSelection: 'Font Size',
      customFontSizeUnit: '',
    })

    return {
      apply: function apply(size: string) {
        const currentSizeWithVar: any = editor.opts.fontSize.find(
          (x: any) => x.value === size.toString(),
        )
        let currentSize = editor.helpers.getPX(
          $(editor.selection.element()).css('font-size'),
        )

        if (currentSizeWithVar.value === currentSize.toString()) {
          editor.format.removeStyle('font-size')
        } else {
          editor.format.applyStyle('font-size', currentSizeWithVar.valueWithVar)
        }
      },

      refreshOnShow: function refreshOnShow(dropdown: any, $btn: any) {
        let currentSize = editor.helpers.getPX(
          $(editor.selection.element()).css('font-size'),
        )

        const searchKey = `.fr-command[data-param1="${currentSize.toString()}"][data-cmd="customFontSize"]`

        // Ensure dropdown is a valid DOM element
        if (dropdown) {
          // First remove active class from any currently active elements
          const activeElements = dropdown
            .parent()
            .find('.fr-command[data-cmd="customFontSize"].fr-active')
          activeElements.removeClass('fr-active').attr('aria-selected', false)

          // Debug: Check if searchKey selector finds any elements
          const targetElements = dropdown.parent().find(searchKey)

          // Add active class to matching elements
          targetElements.addClass('fr-active').attr('aria-selected', true)
        }
      },

      refresh: function refresh(dropdown: any) {
        if (editor.opts.customFontSizeSelection) {
          let currentSize = $(editor.selection.element()).css('font-size')

          dropdown.find('> span').text(currentSize)
        }
      },
    }
  }

  Editor.RegisterCommand('customFontSize', {
    type: 'dropdown',
    title: 'Font Size',
    displaySelection: function displaySelection(editor: FroalaEditor) {
      return editor.opts.customFontSizeSelection
    },
    displaySelectionWidth: 30,
    defaultSelection: function defaultSelection(editor: FroalaEditor) {
      return editor.opts.customFontSizeDefaultSelection
    },
    html: function html() {
      let html = '<ul class="fr-dropdown-list" role="presentation">'
      let sizes = this.opts.fontSize

      for (let i = 0; i < sizes.length; i++) {
        let size = sizes[i]
        html +=
          '<li role="presentation"><a class="fr-command" tabIndex="-1" role="option" data-cmd="customFontSize" data-param1="'
            .concat(size.value)
            .concat(this.opts.customFontSizeUnit ?? '', '" title="')
            .concat(size.label, '">')
            .concat(size.label, '</a></li>')
      }
      html += '</ul>'
      return html
    },
    callback: function callback(cmd: string, val: string) {
      this.customFontSize.apply(val)
    },
    refresh: function refresh(dropdown: any) {
      this.customFontSize.refresh(dropdown)
    },
    refreshOnShow: function refreshOnShow(dropdown: any, $btn: any) {
      this.customFontSize.refreshOnShow(dropdown, $btn)
    },
    plugin: 'customFontSize',
  })

  Editor.DefineIcon('customFontSize', {
    NAME: 'text-height',
    SVG_KEY: 'fontSize',
  })
}
