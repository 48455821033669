import {
  CButton,
  spacing,
  VNode,
  VNodeBtnProps,
  VNodeData,
  VNodeFormBtnProps,
} from '@/js/lib/helper-classes'
import uuid from 'uuid/v4'

export class VNodeCCardBtnProps extends VNodeBtnProps {
  form = false
  isBlock = true
  isCardButton = true
  vnodeId = uuid()
}

class CCardButton extends CButton {
  data = new VNodeData({
    props: new VNodeCCardBtnProps(),
  })
}

export class CCard extends VNode {
  tag = 'c-card'
  data = new VNodeData({
    props: {
      ...spacing({
        margin: {
          vertical: 'my-0',
          horizontal: 'mx-0',
        },
        padding: {
          vertical: 'py-1',
          horizontal: 'px-1',
        },
      }),
      background: {
        image: '',
        size: 'cover',
        repeat: 'no-repeat',
        y: 0,
        active: 'image',
      },
      border: '',
      borderRadius: 0,
      shadow: '',
      cardItems: [new CCardItem(), new CCardItem(), new CCardItem()],
      alignment: {
        column: false,
        verticalAlignment: 'start',
        horizontalAlignment: 'start',
      },
      layout: 'narrow',
      layoutGap: 10,
      layoutWrap: 'unset',
      layoutSpacing: 'space-evenly',
      layoutView: 'list',
      itemStyles: {
        borderStyle: '',
        borderPosition: {
          all: 'set',
          lft: '',
          ryt: '',
          btm: '',
          top: '',
        },
        borderColor: '',
        borderRadius: 0,
        shadow: '',
        borderWidth: 0,
      },
    },
  })
  children = []
}

class CCIcon {
  set = 'material-icon'
  selector = 'star_half'
  size = 2
  themeColorId = ''
  themeColorAlpha = 1
  alignment = 'center'
}

export class CCardItem {
  template = {
    gap: 10,
    icon: new CCIcon(),
    headline:
      '<h5 class="font-style-lead"><strong>Feature element</strong></h5>',
    text: '<p class="text-lead">This is awesome element has more variants and fully customizable properties.</p>',
    enableIcon: true,
    enableHeadline: true,
    enableText: true,
    enableBackground: true,
    enableButton: false,
    button: new CCardButton(),
    useIconAsImage: false,
  }

  background = {
    image: '',
    size: 'cover',
    repeat: 'no-repeat',
    y: 0,
    active: 'image',
  }
  borderPosition = {
    all: 'set',
    lft: '',
    ryt: '',
    btm: '',
    top: '',
  }
  border = ''
  borderRadius = null
  shadow = ''
  class = {}
  style = {}
  vnodeId = uuid()
  domProps = {}
  attrs = {}

  cardWidth = 18

  constructor() {
    let _spacing = spacing({
      margin: {
        vertical: 'my-0',
        horizontal: 'mx-0',
      },
      padding: {
        vertical: 'py-1',
        horizontal: 'px-1',
      },
    })
    this.margin = _spacing.margin
    this.padding = _spacing.padding
  }
}
