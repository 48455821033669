<template>
  <div :id="vnodeId" :data-vnode-id="`${vnodeId}`">
    <ContainerAbstractTemplate
      :classes="classes"
      :show-context-menu="showContextMenu"
      :mouse-leave-action="() => {}"
      :mouse-over-action="target"
      :container-bg-color="ContainerBgColor"
      :container-bg-image="ContainerBgImage"
      :v-node-id="`${vnodeId}-${name}`"
      :style="{}"
      :on-container-click="select"
      :data-vnode-id="`${vnodeId}`"
    >
      <card-item-control
        v-for="(item, idx) in cardItems"
        :key="item.id"
        :class="stylesheet.classes.cardItem"
        :layout="layout"
        :layout-view="layoutView"
        :index="idx"
        v-bind="item"
        :border-radius="itemBorderRadius(item.borderRadius)"
        :border-style="itemBorderStyle(item.borderStyle)"
        :border-color="itemBorderColor(item.borderColor)"
        :border-width="itemBorderWidth(item.borderWidth)"
        :shadow="itemShadow(item.shadow)"
        :item-styles="itemStyles"
      />
    </ContainerAbstractTemplate>
  </div>
</template>

<script>
import ContainerAbstractTemplate from '@/js/components/v3/editor/nodes/abstracts/ContainerAbstractTemplate.vue'
import ContainerAbstractScript from '@/js/components/v3/editor/nodes/abstracts/ContainerAbstractScript'
import CardItemControl from '@/js/components/v3/editor/nodes/card/CardItemControl.vue'
import FbVNode from '@/js/components/base/FbVNode.vue'
import FirebaseNodeV3 from '@/js/components/v3/base/FirebaseNode'
import { mapGetters } from 'vuex'
import * as getters from '@/js/store/modules/getters'

export default FbVNode.extend({
  name: 'CCard',
  mixins: [ContainerAbstractScript, FirebaseNodeV3],
  components: {
    CardItemControl,
    ContainerAbstractTemplate,
  },
  props: {
    offset: Number,
    flexAlignment: Object,
    borderRadius: Number,
    borderWidth: Number,
    borderColor: String,
    borderStyle: String,
    borderPosition: Object,
    shadow: {
      type: String,
      default: 'shadow-none',
    },
    cardItems: {
      required: true,
      default: () => [],
    },
    layout: {
      type: String,
      required: true,
    },
    layoutGap: {
      type: Number,
      required: true,
    },
    layoutWrap: {
      type: String,
      required: true,
    },
    layoutSpacing: {
      type: String,
      required: true,
    },
    layoutView: {
      type: String,
      required: true,
    },
    itemStyles: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      name: 'CCard',
    }
  },
  computed: {
    ...mapGetters({
      viewMode: getters.VIEW_MODE,
    }),
    extraClasses() {
      return {
        [this.stylesheet.classes.cardContainer]: true,
      }
    },
    otherStyles() {
      let cardItemStyle = {}
      let extraStyle = {
        flexWrap: this.layoutWrap,
        justifyContent: this.layoutSpacing,
        flexDirection: 'unset',
      }
      if (this.viewMode === 'phone' && this.layoutView !== 'list') {
        extraStyle.flexWrap = 'wrap'
      }

      if (this.layoutView === 'list') {
        extraStyle.flexDirection = 'column'
      }

      if (this.layoutView === 'list' && this.layoutWrap !== 'wrap') {
        cardItemStyle = {
          flex: '0 0 100%',
          maxWidth: '100%',
          width: '100% !important',
        }
      }

      return {
        cardContainer: {
          display: 'flex',
          gap: `${this.layoutGap}px`,
          width: '100%',
          margin: '0.1rem',
          ...extraStyle,

          '@global': {
            '.vnode--selected': {
              cursor: 'pointer',
              outline: '#3c79e0 solid 3px',
            },
          },
        },
        cardItem: {
          ...cardItemStyle,
        },
      }
    },
    localClasses() {
      // overrides localClasses from ContainerAbstractScript
      return {
        'position-relative': true,
        [`container-${this.viewMode}`]: true,
        [this.bootstrapAlignment]: true,
        [`${this.stylesheet.classes.root}`]: true,
        [this.shadow]: true,
        [`${this.stylesheet.classes.containerBorderRadius}`]: true,
        ...this.extraClasses,
      }
    },
  },
  methods: {
    itemShadow(shadow) {
      return shadow || this.itemStyles.shadow
    },
    itemBorderWidth(borderWidth) {
      return borderWidth || this.itemStyles.borderWidth
    },
    itemBorderRadius(borderRadius) {
      return borderRadius || borderRadius === 0
        ? borderRadius
        : this.itemStyles.borderRadius
    },
    itemBorderColor(borderColor) {
      return borderColor || borderColor === ''
        ? borderColor
        : this.itemStyles.borderColor
    },
    itemBorderStyle(borderStyle) {
      return borderStyle && borderStyle !== ''
        ? borderStyle
        : this.itemStyles.borderStyle
    },
  },
})
</script>

<style scoped></style>
