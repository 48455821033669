<template>
  <component :is="currentTag" class="fr-view"></component>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'FroalaView',
  props: {
    tag: {
      type: String,
      default() {
        return 'div'
      }
    },
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      currentTag: 'div',
      element: null,
    }
  },

  watch: {
    value: function (newValue) {
      this.element.innerHTML = newValue
    },
  },

  created: function () {
    this.currentTag = this.tag || this.currentTag
  },

  mounted: function () {
    this.element = this.$el

    if (this.value) {
      this.element.innerHTML = this.value
    }
  },
})
</script>
