<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    :id="vnodeId"
    ref="draftWrapperRef"
    :data-vnode-id="vnodeId"
    class="cvt-editor-text"
    :class="editorClasses"
    draggable="false"
    @mouseover="target"
    @click="enableEditor"
    @dblclick="resizeContent"
    @contextmenu="(event) => showContextMenuIfNotEditing(event)"
    @dragstart="dragstart"
    @dragend="dragend"
  >
    <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

    <!-- main vdom node -->
    <animated-placeholder :loading="isLoading">
      <div
        v-once
        class="draft-editor"
        :class="stylesheet.classes.cvtListContainer"
      >
        <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
        <froala-text-editor
          ref="frolaWrapper"
          :content="contentsHTMLModel"
          :tag="'div'"
          :config="froalaConfiguration"
          @change="saveWithHtml"
          @contentChanged="resizeContent"
        ></froala-text-editor>
      </div>
    </animated-placeholder>
    <template v-if="showActionButtonsOnClick && !isGridBuilder">
      <!--THis is only here because of freshwork in v1 gridbuilder,
      once they migrate to v2 or v3, it will become irrelevant-->
      <action-buttons
        v-if="selected"
        @duplicate="duplicateAndValidate"
        @remove="removeAndValidate"
      />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { rgbToHex } from '../../../lib/color-utils'
import * as getters from '../../../store/modules/getters'
import * as mutations from '../../../store/modules/mutations'
import FbVNode from '../../base/FbVNode.vue'
import AnimatedPlaceholder from '../../common/AnimatedPlaceholder.vue'
import { FroalaAfterCommand } from '../../../components/common/froala-after-command'
import _ from 'lodash'
import FroalaTextEditor from '@/js/components/froal-component/FroalaTextEditor.vue'
import { FROALA_FONT_SIZES } from '@/js/lib/font'

export default FbVNode.extend({
  components: {
    AnimatedPlaceholder,
    FroalaTextEditor,
  },
  props: {
    contents: Object,
    contentsHTML: String,
    shadow: {
      type: String,
      default: 'shadow-none',
    },
    dc: {
      type: Object,
      default: () => {},
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      name: 'Draft',
      draftToolsVisible: false,
      isDirty: false,
      froala: null,
      editor: null,
      selectedSearchText: null,
      selectedSearchRange: null,
      _prevContentsHTML: null,
      _fontSizeOptions: null,
      _fontFamilyOptions: null,
      _hexColors: null,
    }
  },
  computed: {
    ...mapGetters({
      range: getters.DRAFT_RANGE,
      rangeFormat: getters.DRAFT_RANGE_FORMAT,
      colors: getters.THEME_GET_COLORS,
      availableFonts: getters.THEME_AVAILABLE_FONTS,
      typography: getters.THEME_TYPOGRAPHY,
      theme: getters.THEME,
      advancedMenuOpen: getters.ADVANCED_MENU_OPEN,
      isStandalonePopupActive: getters.STANDALONE_POPUP_ACTIVE,
      config: getters.AUTH_GET_USER_CONFIG,
    }),
    contentsHTMLModel: {
      get() {
        return this.contentsHTML
      },
    },
    editorClasses() {
      return {
        ...this.classes,
        'w-100': this.isGridBuilder,
        [this.shadow]: true,
        [this.stylesheet.classes.shadowStyling]: true,
      }
    },
    getFroalaContainerId() {
      let froalaContainerId = '#froala-portal'

      if (this.isStandalonePopupActive) {
        froalaContainerId = '#froala-portal-sda-popup-builder'
      }

      return froalaContainerId
    },
    draftStyleClasses() {
      return {}
    },
    styles() {
      return {
        cvtListContainer: {
          '& .cvt-clist': {
            padding: '0 0 0 .8em',
            '& li': {
              paddingLeft: '.4rem',
              marginLeft: '1.25rem',
              '&::marker': {
                fontFamily: 'FontAwesome',
                display: 'inline-block',
              },
            },
            '&.check': {
              '& > li': {
                'list-style-type': '"\\f00c"',
              },
            },
            '&.square-check': {
              '& > li': {
                'list-style-type': '"\\f14a"',
              },
            },
            '&.circle-check': {
              '& > li': {
                'list-style-type': '"\\f058"',
              },
            },
            '&.calendar-check': {
              '& > li': {
                'list-style-type': '"\\f274"',
              },
            },
            '&.phone': {
              '& > li': {
                'list-style-type': '"\\f095"',
              },
            },
            '&.mobile': {
              '& > li': {
                'list-style-type': '"\\f10b"',
              },
            },
            '&.phone-square': {
              '& > li': {
                'list-style-type': '"\\f098"',
              },
            },
            '&.whatsapp': {
              '& > li': {
                'list-style-type': '"\\f232"',
              },
            },
            '&.heart': {
              '& > li': {
                'list-style-type': '"\\f004"',
              },
            },
            '&.star': {
              '& > li': {
                'list-style-type': '"\\f005"',
              },
            },
            '&.arrow-right': {
              '& > li': {
                'list-style-type': '"\\f061"',
              },
            },
            '&.chevron-right': {
              '& > li': {
                'list-style-type': '"\\f054"',
              },
            },
            '&.angle-right': {
              '& > li': {
                'list-style-type': '"\\f105"',
              },
            },
            '&.share': {
              '& > li': {
                'list-style-type': '"\\f064"',
              },
            },
            '&.long-arrow-right': {
              '& > li': {
                'list-style-type': '"\\f178"',
              },
            },
            '&.dot-circle': {
              '& > li': {
                'list-style-type': '"\\f192"',
              },
            },
            '&.hand-point-right': {
              '& > li': {
                'list-style-type': '"\\f0a4"',
              },
            },
            '&.lightbulb': {
              '& > li': {
                'list-style-type': '"\\f0eb"',
              },
            },
            '&.bolt': {
              '& > li': {
                'list-style-type': '"\\f0e7"',
              },
            },
            '&.times': {
              '& > li': {
                'list-style-type': '"\\f00d"',
              },
            },
            '&.times-circle': {
              '& > li': {
                'list-style-type': '"\\f057"',
              },
            },
            '&.book': {
              '& > li': {
                'list-style-type': '"\\f02d"',
              },
            },
            '&.quote-left': {
              '& > li': {
                'list-style-type': '"\\f10d"',
              },
            },
          },
        },
        shadowStyling: {
          '&.text-shadow-basic': {
            textShadow: '2px 3px 5px rgba(0,0,0,0.5)',
          },
          '&.text-shadow-hard': {
            textShadow: '5px 5px 0px rgba(0,0,0,0.2)',
          },
          '&.text-shadow-heavy': {
            textShadow:
              '0px 4px 3px rgba(0,0,0,0.4),0px 8px 13px rgba(0,0,0,0.1),0px 18px 23px rgba(0,0,0,0.1)',
          },
          '&.text-shadow-letterpress': {
            textShadow: '2px 2px 3px rgba(255,255,255,0.1)',
          },
        },
      }
    },
    getFroalaEditorId() {
      return this.config?.FROALA_EDITOR_ID
    },
    froalaConfiguration() {
      let hexColors = this.hexColors
      let fontFamily = this.fontFamilyOptions

      let styleFonts = {}
      let styleFontSize = {}

      Object.keys(this.typography).forEach((key) => {
        styleFonts[key] = this.typography[key]['font']
        styleFontSize[key] = this.typography[key]['size']
      })
      let moreMiscButtons = [
        'customParagraphStyle',
        'textColor',
        'bold',
        'italic',
        'underline',
        'strikeThrough',
        'clearFormatting',
        'alignLeft',
        'alignCenter',
        'alignRight',
        'insertLink',
        'undo',
        'redo',
        'lineHeight',
        'customFontSize',
        'fontWeight',
        'backgroundColor',
        'formatOLSimple',
        'customList',
      ]
      if (this.config.CHATGPT_ACTION_ENABLED) {
        moreMiscButtons.splice(1, 0, 'chatGPT')
      }
      return {
        key: this.getFroalaEditorId,
        toolbarInline: true,
        placeholderText: this.$t(
          'editor.section.grid_item.edit.placeholder_text',
        ),
        paragraphFormatSelection: true,
        toolbarContainer: this.getFroalaContainerId,
        charCounterCount: true,
        toolbarVisibleWithoutSelection: true,
        initOnClick: false,
        keepFormatOnDelete: true,
        fontSizeUnit: '',
        pastePlain: true,
        pasteDeniedTags: ['div'],
        pasteDeniedAttrs: ['class', 'style'],
        paragraphMultipleStyles: false,
        toolbarSticky: true,
        toolbarButtons: {
          moreMisc: {
            buttons: moreMiscButtons,
            buttonsVisible: 20,
          },
        },
        colorsText: [...hexColors, 'REMOVE'],
        colorsBackground: [...hexColors, 'REMOVE'],
        fontFamilySelection: true,
        fontFamily: fontFamily,
        fontSize: FROALA_FONT_SIZES,
        lineHeights: {
          Default: '',
          Single: '1',
          1.15: '1.15',
          1.5: '1.5',
          Double: '2',
        },
        linkEditButtons: [
          'linkOpen',
          'linkEdit',
          'linkRemove',
          'textColor',
          'backgroundColor',
        ],
        linkAutoPrefix: 'https://',
        events: {
          'commands.after': function (cmd, param1, param2) {
            FroalaAfterCommand(this, cmd, param1, param2, [])
          },
        },
      }
    },
    hexColors() {
      return this._hexColors || (this._hexColors = this.getHexColors())
    },
    fontFamilyOptions() {
      return (
        this._fontFamilyOptions ||
        (this._fontFamilyOptions = this.getFontFamily())
      )
    },
    fontSizeOptions() {
      return (
        this._fontSizeOptions || (this._fontSizeOptions = this.getFontSizes())
      )
    },
  },
  methods: {
    ...mapMutations({
      updateDraftRange: mutations.DRAFT_UPDATE_RANGE,
      setFroalaInstance: mutations.DRAFT_SET_FROALA_INSTANCE,
      updateDraftRangeFormat: mutations.DRAFT_UPDATE_RANGE_FORMAT,
      updateTypography: mutations.THEME_UPDATE_DATA,
    }),

    showContextMenuIfNotEditing(e) {
      // There is this strange issue which occurs when user is using old-builder and he right-clicks on text-element which
      // is being edited to open the context menu. Then sometimes accessing the hasFocus method of text-element(node) being edited throws error.
      // As per my initial oberservation, this is basically due to the plugin not behaving correctly. The optional-chaining is quick fix for it.
      if (!this.$refs.frolaWrapper.editor?.node?.hasFocus?.()) {
        e.stopPropagation()
      } else {
        this.showContextMenu(e)
      }
    },

    getHexColors() {
      // Convert THEME_RGBA_COLOR to HEX format
      return Object.keys(this.colors).map((key) => {
        let r = this.colors[key]['color'][0]
        let g = this.colors[key]['color'][1]
        let b = this.colors[key]['color'][2]
        return rgbToHex(r, g, b)
      })
    },
    focusAndEditFroalaText() {
      // enables the froala-toolbar at top
      this.$refs.frolaWrapper.$el.click()
      this.$froalaEditor.events.focus()
      // checks whether user has selecgted any text
      // let isTextSelected =
      //   this.$refs.frolaWrapper.editor.selection?.get().toString() !== ''

      // if user has not selected any text in frola then we want to restore the cursor position
      // if (!isTextSelected) {

      // sets the cursor position at the end of the text
      // this.$refs.frolaWrapper.editor.selection.setAtEnd(
      //   this.$refs.frolaWrapper.editor.$el.get(0),
      // )
      // this.$refs.frolaWrapper.editor.selection.restore()
      // }

      // selects the text-properties in right-side-bar-panel
      this.selectSelfForHelp()
    },
    refreshFroalaButtons() {
      this.$refs.frolaWrapper.editor.button.bulkRefresh()
    },
    getFontFamily() {
      let fontFamily = {}
      this.availableFonts.forEach((font) => {
        fontFamily[`${font.familyName}, ${font.genericFamily}`] =
          font.familyName
      })
      return fontFamily
    },
    enableEditor(e) {
      if (this.isGridBuilder && !this.advancedMenuOpen) {
        return
      }

      if (!this.selected) {
        this.select(e)
        if (this.$froalaEditor.edit) {
          this.$froalaEditor.edit.on()
        }
        if (this.$froalaEditor.events) {
          this.$froalaEditor.events.focus()
          this.$froalaEditor.events.enableBlur()
        }
      }
    },
    getHtml() {
      return this.$froalaEditor.root.innerHTML
    },
    saveAction(htmlStr) {
      return Promise.all([this.updateProp('contentsHTML', htmlStr)])
    },
    resizeContent: _.debounce(function () {
      if (this.isGridBuilder) {
        console.log(' Called resizeContent')
        this.resize()
      }
    }, 150),
    saveWithHtml: _.debounce(function (html) {
      return this.saveAction(html)
    }, 250),
  },
  beforeUpdate() {
    // Only update if contents changed
    if (this._prevContentsHTML === this.contentsHTML) {
      return false
    }
    this._prevContentsHTML = this.contentsHTML
  },
  mounted() {
    // Cache DOM references
    this.$froalaEditor = this.$refs.frolaWrapper.editor
  },
})
</script>

<style lang="scss">
@import '@/scss/utils';
.draft-editor {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: pre-line;
}
.cvt-editor-text {
  position: relative;
}

.froala-editor {
  min-width: 50px;
  padding: 0;
  overflow-y: visible;
  p {
    margin-bottom: 0;
    min-height: 50px;
  }
}

.oldbuilder {
  &.desktop-mode {
    &.layout-style-1 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }

  &.mobile-mode {
    &.layout-style-1 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }
}

.gridbuilder {
  &.desktop-mode {
    // &.layout-style-1 {
    //   .froala-portal {
    //     .fr-toolbar {
    //       top: 70px !important;
    //     }
    //   }
    // }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 50px !important;
        }
      }
    }
  }

  &.mobile-mode {
    // &.layout-style-1 {
    //   .froala-portal {
    //     .fr-toolbar {
    //       top: 70px !important;
    //     }
    //   }
    // }

    &.layout-style-2 {
      .froala-portal {
        .fr-toolbar {
          top: 70px !important;
        }
      }
    }
  }
}

.slide-engine-wrapper {
  .fr-toolbar {
    top: 175px;
  }
}

#froala-portal,
#froala-portal-sda-popup-builder {
  .fr-toolbar {
    display: block !important;
    position: absolute;
    border-radius: 10px !important;
    z-index: 21;

    bottom: unset !important;
    left: unset !important;
    right: unset !important;

    margin-left: auto !important;
    margin-right: auto !important;
  }

  .fr-toolbar.fr-top {
    border-bottom: 1px solid rgb(204, 204, 204) !important;
  }

  .fr-expanded {
    border-bottom-right-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  .fr-toolbar .fr-command.fr-btn span {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
      'Helvetica Neue', Arial, sans-serif;
  }

  .admin-description-wrapper {
    .fr-toolbar {
      position: unset !important;
      width: unset !important;
      border-bottom-right-radius: unset !important;
      border-bottom-left-radius: unset !important;
    }
  }

  .fr-toolbar
    .fr-dropdown-menu
    .fr-dropdown-wrapper
    .fr-dropdown-content
    ul.fr-dropdown-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 8px;
    margin: 5px 10px !important;

    li {
      display: inline-block;
      padding: 0px 0px !important;
      border: 1px solid #f1f1f1;
      border-radius: 5px;
      min-width: 45px !important;
      display: flex;
      justify-content: center;

      a {
        font-family: 'Fontawesome';
        padding: 5px !important;
      }
    }
  }
}
</style>
