import { GlobalParagraphStyles } from '@/js/FroalaConfigurations'

export function cleanFroalaContent(html) {
  // Regex to match froala-editor divs with or without data-v attributes
  const froalaRegex = /<div[^>]*class="[^"]*froala-editor[^"]*"[^>]*>/g;

  // Remove all opening froala-editor div tags
  let cleaned_html = html.replace(froalaRegex, '');

  // Remove all closing div tags that correspond to froala-editor divs
  cleaned_html = cleaned_html.replace(/<\/div>/g, '');

  // Remove empty HTML tags (any tag with nothing between opening and closing tags)
  // const emptyTagsRegex = /<([a-zA-Z])[^>]*>\s*<br\s*\/?>\s*<\/([a-zA-Z])>/g;
  // cleaned_html = cleaned_html.replace(emptyTagsRegex, '');

  return cleaned_html.trim();
}


export function FroalaAfterCommand(self, cmd, param1, param2, paragraphFontStyles) {
  const isClearFormatting = cmd === 'clearFormatting'

  const isCustomTextColor = cmd === 'customTextColor'
  const isTextColor = cmd === 'textColor'
  const isBackgroundColor = cmd === 'backgroundColor'
  const isCustomBackgroundColor = cmd === 'customBackgroundColor'

  if (isClearFormatting) {
    let paragraphString = self.selection.element()
    for (let style of GlobalParagraphStyles) {
      if (style.name === 'font-style-normalText') {
        if (!self?.node?.hasClass(paragraphString, style.name)) {
          self.paragraphFormat.apply(style.tag)
          self.paragraphStyle.apply(style.name)
        }
      } else {
        if (self?.node?.hasClass(paragraphString, style.name)) {
          // check if it contains it, then remove
          self.paragraphStyle.apply(style.name)
          self.paragraphFormat.apply(style.tag)
        }
      }
    }
  }

  if (isTextColor || isBackgroundColor) {
    // For some reason selected text is only retained here, so we commit it to state
    const selection = self.selection.get();
    if (selection.rangeCount > 0) {
      self.selectedSearchRange = selection.getRangeAt(0);
      self.selectedSearchText = selection.getRangeAt(0).toString();
    }
  }

  if (isCustomBackgroundColor) {
    // For some reason selected text is lost here

    if (self.selectedSearchText && self.selectedSearchRange) {
      const textElement = self.selection.element();
      const backgroundColor = window.getComputedStyle(textElement).backgroundColor

      // span with the selected color
      const span = document.createElement('span');
      span.style.backgroundColor = backgroundColor;
      span.textContent = self.selectedSearchText;

      // selected text with the span
      self.selectedSearchRange.deleteContents();
      self.selectedSearchRange.insertNode(span);

      // Clear the selection
      self.selection.get().removeAllRanges();
      self.selectedSearchText = null
      self.selectedSearchRange = null
    }

  }

  if (isCustomTextColor) {
    // For some reason selected text is lost here

    if (self.selectedSearchText && self.selectedSearchRange) {
      const textElement = self.selection.element();
      const textColor = window.getComputedStyle(textElement).color

      // span with the selected color
      const span = document.createElement('span');
      span.style.color = textColor;
      span.textContent = self.selectedSearchText;

      // selected text with the span
      self.selectedSearchRange.deleteContents();
      self.selectedSearchRange.insertNode(span);

      // Clear the selection
      self.selection.get().removeAllRanges();
      self.selectedSearchText = null
      self.selectedSearchRange = null
    }
  }
}