import * as _ from 'lodash'
import googleFonts from './typography/google-fonts'

export class Font {
  constructor(font) {
    this.familyName = font.familyName
    this.genericFamily = font.genericFamily
    this.source = font.source
    this.tags = font.tags || []
  }

  toCSSValue() {
    return `"${this.familyName}", ${this.genericFamily}`
  }

  toStyle(size, weight = '400') {
    return {
      'font-family': this.toCSSValue(),
      'font-size': size ? `${size}` : null,
      'font-weight': weight,
    }
  }

  toJSON() {
    return {
      familyName: this.familyName,
      genericFamily: this.genericFamily,
      source: this.source,
    }
  }

  kababName() {
    return _.kebabCase(this.familyName)
  }

  className() {
    return `.${this.kababName()}`
  }

  froalaClass() {
    return `font-family-${this.kababName()}`
  }

  froalaClassName() {
    return `.${this.froalaClass()}`
  }

  isValid() {
    return this.familyName && this.genericFamily && this.source
  }

  isInvalid() {
    return !this.isValid()
  }

  tagString() {
    return this.tags.length > 0 ? `:${this.tags}` : ''
  }

  webfontName() {
    return `${this.familyName}${this.tagString()}`
  }

  // return on tags that are numbers becuse tags
  // include 300i which is a string and cannot be passed to font-weight
  weights() {
    return this.tags.filter((t) => {
      return _.isNumber(t)
    })
  }
}

export class FontCollection {
  constructor(fonts) {
    this.fonts = fonts
  }

  find(predicate) {
    return _.find(this.fonts, predicate)
  }

  froalaClassWhiteList() {
    return this.fonts.map((f) => f.kababName())
  }
}

export class FroalaFormat {
  static fromStyle(style, format) {
    if (style === 'blockquote') {
      return new BlockQuoteFroalaFormat(format)
    }
    return new FroalaFormat(format)
  }
  constructor({
    font,
    size,
    defaultTextColor,
    defaultAltTextColor,
    weight = '400',
    selectedWeights = ['400'],
  }) {
    this.font = font
    this.size = size
    this.defaultTextColor = defaultTextColor || null
    this.defaultAltTextColor = defaultAltTextColor || null
    this.weight = weight
    this.selectedWeights = selectedWeights
  }

  fontClass() {
    return GOOGLE_FONTS_COLLECTION.find((f) => {
      return f.kababName() === this.font
    })
  }

  getDefaultTextColor() {
    return this.defaultTextColor
      ? {
        color: this.defaultTextColor.startsWith('#')
          ? this.defaultTextColor
          : `var(--${this.defaultTextColor})`,
      }
      : {}
  }

  getDefaultAltTextColor() {
    return this.defaultAltTextColor
      ? {
        color: this.defaultAltTextColor.startsWith('#')
          ? this.defaultAltTextColor
          : `var(--${this.defaultAltTextColor})`,
      }
      : {}
  }

  toStyle() {
    return _.merge(
      this.fontClass().toStyle(`${this.size}px`, this.weight),
      this.getDefaultTextColor(),
    )
  }

  getWeights() {
    return (
      this.fontClass().tags?.map((tag) =>
        FONT_WEIGHTS.find((f) => f.value === tag),
      ) || []
    )
  }

  toRange() {
    return {
      font: this.font,
      size: this.size,
    }
  }
}

export class BlockQuoteFroalaFormat extends FroalaFormat {
  getDefaultTextColor() {
    return this.defaultTextColor
      ? {
        color: this.defaultTextColor.startsWith('#')
          ? `${this.defaultTextColor} !important`
          : `var(--${this.defaultTextColor}) !important`,
      }
      : {}
  }

  getDefaultAltTextColor() {
    return this.defaultAltTextColor
      ? {
        color: this.defaultAltTextColor.startsWith('#')
          ? `${this.defaultAltTextColor} !important`
          : `var(--${this.defaultAltTextColor}) !important`,
      }
      : {}
  }

  toStyle() {
    return _.merge(
      this.fontClass().toStyle(`${this.size}px`, this.weight),
      this.getDefaultTextColor(),
      { 'border-left': 'none' },
    )
  }
}

export class ButtonFormat {
  constructor({
    fontFamily,
    fontSize,
    defaultTextColor,
    defaultBackgroundColor,
    fontWeight,
    subtextStyle = { fontSize: '14', fontWeight: '200', fontFamily: 'arimo' },
  }) {
    this.fontFamily = fontFamily
    this.fontSize = fontSize
    this.defaultTextColor = defaultTextColor || null
    this.defaultBackgroundColor = defaultBackgroundColor || null
    this.fontWeight = fontWeight
    this.subtextStyle = subtextStyle

    this.computedSubtextStyle = this.compileSubtextStyle()
    this.computedButtonFontStyle = this.getButtonFontStyle()
  }

  fontClass(fontFamily) {
    return GOOGLE_FONTS_COLLECTION.find((f) => {
      return f.kababName() === (fontFamily || this.fontFamily)
    })
  }

  getDefaultTextColor() {
    return this.defaultTextColor
      ? {
        color: this.defaultTextColor.startsWith('#')
          ? `${this.defaultTextColor}`
          : `var(--${this.defaultTextColor})`,
      }
      : {}
  }

  getButtonFontStyle() {
    return this.fontClass().toStyle(`${this.fontSize}px`, this.fontWeight)
  }

  compileSubtextStyle() {
    const font = this.fontClass(this.subtextStyle.fontFamily)
    return {
      'font-family': `${font.familyName.toLowerCase()}, ${font.genericFamily}`,
      'font-size': `${this.subtextStyle.fontSize}px`,
      'font-weight': this.subtextStyle.fontWeight,
    }
  }
}

export class FroalaLinkFormat {
  constructor({ defaultTextColor }) {
    this.defaultTextColor = defaultTextColor
  }

  getDefaultTextColor() {
    return this.defaultTextColor
      ? {
        color: this.defaultTextColor.startsWith('#')
          ? `${this.defaultTextColor}`
          : `var(--${this.defaultTextColor})`,
      }
      : {}
  }

  getLinkFontStyle() {
    return this.getDefaultTextColor()
  }
}

export const GOOGLE_FONTS = googleFonts
  .sort((a, b) => {
    if (a.familyName < b.familyName) return -1
    if (a.familyName > b.familyName) return 1
    return 0
  })
  .map((f) => new Font(f))

export const GOOGLE_FONTS_COLLECTION = new FontCollection(GOOGLE_FONTS)

export const FONT_SIZES = [
  { label: '12', value: '12', },
  { label: '14', value: '14', },
  { label: '16', value: '16', },
  { label: '18', value: '18', },
  { label: '20', value: '20', },
  { label: '22', value: '22', },
  { label: '24', value: '24', },
  { label: '28', value: '28', },
  { label: '30', value: '30', },
  { label: '34', value: '34', },
  { label: '36', value: '36', },
  { label: '48', value: '48', },
  { label: '50', value: '50', },
  { label: '54', value: '54', },
  { label: '60', value: '60', },
  { label: '72', value: '72', },
  { label: '84', value: '84', },
  { label: '96', value: '96', },
]

export const FROALA_FONT_SIZES = FONT_SIZES.map((fontSize) => {
  return {
    label: fontSize.label,
    value: fontSize.value,
    valueWithVar: `var(--font-size-${fontSize.value})`,
  }
})

export const SUB_FONT_SIZES = [
  { label: '12', value: '12', },
  { label: '13', value: '13', },
  { label: '14', value: '14', },
  { label: '15', value: '15', },
  { label: '16', value: '16', },
]

export const FONT_WEIGHTS = [
  { label: '100 - Thin', value: '100' },
  { label: '200 - ExtraLight', value: '200' },
  { label: '300 - Light', value: '300' },
  { label: '400 - Regular', value: '400' },
  { label: '500 - Medium', value: '500' },
  { label: '600 - SemiBold', value: '600' },
  { label: '700 - Bold', value: '700' },
  { label: '800 - ExtraBold', value: '800' },
  { label: '900 - Black', value: '900' },
]
