import { FONT_WEIGHTS } from '@/js/lib/font'
import * as getters from '@/js/store/modules/getters'
import FroalaEditor from 'froala-editor/js/froala_editor.min'
import { fontPlugin } from '@/js/components/froal-component/font-plugin'
import { Store } from 'vuex'
import { Notification } from 'element-ui'

export const GlobalParagraphStyles = [
  {
    label: '<h1>Title</h1>',
    name: 'font-style-title',
    tag: 'h1',
    style: { class: 'font-style-title' },
  },
  {
    label: '<h2>SubTitle</h2>',
    name: 'font-style-subtitle',
    tag: 'h2',
  },
  {
    label: '<h3>Heading</h3>',
    name: 'font-style-heading',
    tag: 'h3',
  },
  {
    label: '<h4>Alt. Heading</h4>',
    name: 'font-style-alternativeHeading',
    tag: 'h4',
  },
  {
    label: '<h5>Lead</h5>',
    name: 'font-style-lead',
    tag: 'h5',
    style: { class: 'font-style-lead' },
  },
  {
    label: '<p>Normal Text</p>',
    name: 'font-style-normalText',
    tag: 'p',
  },
  {
    label: '<blockquote>Blockquote</blockquote>',
    name: 'font-style-blockquote',
    tag: 'blockquote',
  },
]

function setupFroalaWithOpenAI(store: Store<any>) {
  FroalaEditor.DefineIconTemplate(
    'font_awesome_1',
    '<i role="presentation" class="icon fas fa-robot" aria-hidden="true"></i>',
  )
  FroalaEditor.DefineIcon('chatGPTIcon', {
    NAME: 'robot',
    template: 'font_awesome_1',
  })

  FroalaEditor.RegisterCommand('chatGPT', {
    title: 'Ask chatGPT',
    icon: 'chatGPTIcon',
    undo: false,
    focus: false,
    refreshAfterCallback: false,
    callback: function () {
      const config = store.getters[getters.AUTH_GET_USER_CONFIG]
      if (!config.CHATGPT_API_KEY) {
        // @ts-ignore
        Notification.error({
          title: 'Error',
          message: 'CHATGPT is not Authorization for this account.',
          position: 'bottom-right',
        })
        console.error('CHATGPT is not Authorization for this account.')
        return
      }
      const selection = this.selection.text()
      const data = {
        max_tokens: 256,
        temperature: 0,
        model: 'gpt-3.5-turbo',
        messages: [
          {
            role: 'system',
            content: 'You are an assistant.',
          },
          {
            role: 'user',
            content: selection,
          },
        ],
      }

      fetch('https://api.openai.com/v1/chat/completions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${config.CHATGPT_API_KEY}`,
        },
        body: JSON.stringify(data),
      })
        .then((res) => res.json())
        .then((data) => {
          if (data.id) {
            this.html.insert(data.choices[0].message.content)
          } else {
            console.debug('CHATGPT: ', data.error)
            // @ts-ignore
            Notification.error({
              title: 'Error',
              message: 'Something went wrong',
              position: 'bottom-right',
            })
          }
        })
    },
  })
}

function paragraphCommandCallback(cmdName: string) {
  function paragraphCommandCallbackDecorator(cmd: string, val: string) {
    const self = this as unknown as FroalaEditor
    if (cmd === cmdName) {
      // let paragraphString = self.selection.element()
      GlobalParagraphStyles.forEach((style) => {
        if (val === style.name) {
          self.paragraphFormat.apply(style.tag)
          return
        }
      })
      self.paragraphStyle.apply(val)
    }
  }
  return paragraphCommandCallbackDecorator
}

function RegisterDraftFroalaCommands() {
  let weights: Record<string, string> = {}
  FONT_WEIGHTS.forEach((font: { value: string; label: string }) => {
    weights[font.value] = font.label
  })

  const paragraphFontStyles = Object.assign(
    {},
    ...GlobalParagraphStyles.map((style) => ({
      [style.name]: style.label,
    })),
  )

  FroalaEditor.DefineIcon('fontWeight', {
    NAME: 'text-width',
    template: 'font_awesome',
  })
  FroalaEditor.RegisterCommand('fontWeight', {
    title: 'Font Weight',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: false,
    options: weights,
    callback: function (cmd, val) {
      this.format.applyStyle('font-weight', `${val};`)
    },
    // Callback on refresh.
    refresh: function ($btn) {},
    // Callback on dropdown show.
    refreshOnShow: function ($btn, $dropdown) {},
  })

  FroalaEditor.RegisterCommand('customParagraphStyle', {
    title: 'Font Style',
    type: 'dropdown',
    icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: paragraphFontStyles,
    callback: paragraphCommandCallback('customParagraphStyle'),
    // Callback on refresh.
    refresh: function ($btn) {
      this.paragraphFormat.refresh($btn)
    },
    // Callback on dropdown show.
    refreshOnShow: function ($btn, $dropdown) {
      this.paragraphFormat.refreshOnShow($btn, $dropdown)
      this.paragraphStyle.refreshOnShow($btn, $dropdown)
    },
  })

  const paragraphFontStylesOne = Object.assign(
    {},
    ...GlobalParagraphStyles.filter((x) =>
      [
        'font-style-title',
        'font-style-subtitle',
        'font-style-heading',
        'font-style-alternativeHeading',
      ].includes(x.name),
    ).map((style) => ({
      [style.name]: style.label,
    })),
  )

  FroalaEditor.RegisterCommand('customParagraphStyleOne', {
    title: 'Font Style',
    type: 'dropdown',
    icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: paragraphFontStylesOne,
    callback: paragraphCommandCallback('customParagraphStyleOne'),
    // Callback on refresh.
    refresh: function ($btn) {
      this.paragraphFormat.refresh($btn)
    },
    // Callback on dropdown show.
    refreshOnShow: function ($btn, $dropdown) {
      this.paragraphFormat.refreshOnShow($btn, $dropdown)
      this.paragraphStyle.refreshOnShow($btn, $dropdown)
    },
  })

  const paragraphFontStylesTwo = Object.assign(
    {},
    ...GlobalParagraphStyles.filter((x) =>
      [
        'font-style-lead',
        'font-style-normalText',
        'font-style-blockquote',
      ].includes(x.name),
    ).map((style) => ({
      [style.name]: style.label,
    })),
  )

  FroalaEditor.RegisterCommand('customParagraphStyleTwo', {
    title: 'Font Style',
    type: 'dropdown',
    icon: '&nbsp;&nbsp;Font Style&nbsp;&nbsp;&nbsp;&nbsp;', // this is a quick fix, because we want to show text in place of icon in toolbar
    focus: false,
    undo: false,
    refreshAfterCallback: true,
    options: paragraphFontStylesTwo,
    callback: paragraphCommandCallback('customParagraphStyleTwo'),
    // Callback on refresh.
    refresh: function ($btn) {
      this.paragraphFormat.refresh($btn)
    },
    // Callback on dropdown show.
    refreshOnShow: function ($btn, $dropdown) {
      this.paragraphFormat.refreshOnShow($btn, $dropdown)
      this.paragraphStyle.refreshOnShow($btn, $dropdown)
    },
  })

  FroalaEditor.DefineIcon('customList', {
    NAME: 'list',
    template: 'font_awesome',
  })
  FroalaEditor.RegisterCommand('customList', {
    title: 'Custom List',
    type: 'dropdown',
    focus: true,
    undo: true,
    refreshAfterCallback: true,
    options: {
      default: '&#xf111;',
      check: '&#xf00c;',
      'square-check': '&#xf14a;',
      'circle-check': '&#xf058;',
      'calendar-check': '&#xf274;',
      phone: '&#xf095;',
      mobile: '&#xf10b;',
      'phone-square': '&#xf098;',
      whatsapp: '&#xf232;',
      heart: '&#xf004;',
      star: '&#xf005;',
      'arrow-right': '&#xf061;',
      'chevron-right': '&#xf054;',
      'angle-right': '&#xf105;',
      share: '&#xf064;',
      'long-arrow-right': '&#xf178;',
      'dot-circle': '&#xf192;',
      'hand-point-right': '&#xf0a4;',
      lightbulb: '&#xf0eb;',
      bolt: '&#xf0e7;',
      times: '&#xf00d;',
      'times-circle': '&#xf057;',
      book: '&#xf02d;',
      'quote-left': '&#xf10d;',
    },
    callback: function (cmd, val) {
      this.format.apply('ul', { class: `cvt-clist ${val}` })
      this.cursor.enter(true)
      // this.cursor.backspace();
    },
    // Callback on refresh.
    refresh: function ($btn) {},
    // Callback on dropdown show.
    refreshOnShow: function ($btn, $dropdown) {},
  })
}

export function setupFroala(store: Store<any>) {
  RegisterDraftFroalaCommands()
  setupFroalaWithOpenAI(store)
  fontPlugin(FroalaEditor)
}
