import * as getters from './getters'
import * as mutations from './mutations'
import * as actions from '@/js/store/modules/actions'
import { database } from '@/js/store/services/firebase.service'
import { MARKETPLACE_PATH } from '@/js/lib/helper-classes'


let db = database()
export default {
  state: {
    visible: false,
    pagesBookmarkVisible: false,
    pagesBookmarkPageRef: null,
  },
  getters: {
    [getters.BOOKMARK_BLOCK_VISIBLE]({ visible }) {
      return visible
    },
    [getters.PAGES_BOOKMARK_DIALOG_VISIBLE]({ pagesBookmarkVisible }) {
      return pagesBookmarkVisible
    },
    [getters.PAGES_BOOKMARK_PAGE_REF]({ pagesBookmarkPageRef }) {
      return pagesBookmarkPageRef
    },
  },
  mutations: {
    [mutations.BOOKMARK_BLOCK_SHOW](state) {
      state.visible = true
    },
    [mutations.PAGES_BOOKMARK_BLOCK_SHOW](state, pageRef) {
      state.pagesBookmarkVisible = true
      state.pagesBookmarkPageRef = pageRef
    },
    [mutations.BOOKMARK_BLOCK_HIDE](state) {
      state.visible = false
    },
    [mutations.PAGES_BOOKMARK_BLOCK_HIDE](state) {
      state.pagesBookmarkVisible = false
      state.pagesBookmarkPageRef = null
    },
  },
  actions: {
    async [actions.BOOKMARK_BLOCK_CONFIRM]({ state, commit, rootGetters }, {data, path}) {
      const { orgID, projectID } = rootGetters[getters.AUTH_GET_USER]

      let blockRef = db.ref(
        MARKETPLACE_PATH).child(orgID).child(projectID).child('blocks').push()

      await db.ref(path).once('value', (snap) => {
        return blockRef
          .set({
          ...data,
          vnode: snap.val(),
        })
      })
    },
    async [actions.PAGES_BOOKMARK_BLOCK_CONFIRM]({ state, commit, rootGetters }, {data, path}) {
      if (!state.pagesBookmarkPageRef) {
        throw Error("Invalid Bookmarking setup ")
      }
      const { orgID, projectID } = rootGetters[getters.AUTH_GET_USER]

      let blockRef = db.ref(
        MARKETPLACE_PATH).child(orgID).child(projectID).child('pages').push()

      await db.ref(state.pagesBookmarkPageRef).child('vdom').once('value', (snap) => {
        return blockRef
          .set({
            ...data,
            vdom: snap.val(),
          })
        })
    }
  },
}
