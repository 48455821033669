
<div :id="vnodeId" :data-vnode-id="`${vnodeId}`">
  <ContainerAbstractTemplate
    :classes="classes"
    :show-context-menu="showContextMenu"
    :mouse-leave-action="() => {}"
    :mouse-over-action="target"
    :container-bg-color="ContainerBgColor"
    :container-bg-image="ContainerBgImage"
    :v-node-id="`${vnodeId}-${name}`"
    :style="{}"
    :on-container-click="select"
    :data-vnode-id="`${vnodeId}`"
  >
    <card-item-control
      v-for="(item, idx) in cardItems"
      :key="item.id"
      :class="stylesheet.classes.cardItem"
      :layout="layout"
      :layout-view="layoutView"
      :index="idx"
      v-bind="item"
      :border-radius="itemBorderRadius(item.borderRadius)"
      :border-style="itemBorderStyle(item.borderStyle)"
      :border-color="itemBorderColor(item.borderColor)"
      :border-width="itemBorderWidth(item.borderWidth)"
      :shadow="itemShadow(item.shadow)"
      :item-styles="itemStyles"
    />
  </ContainerAbstractTemplate>
</div>
