
<div>
  <flex-align 
    v-if="isGridBuilder && selected.parent().name === 'GridItemWrapper' && !hideFlexAlignment"
    :mode="mode"
    :disable-alignment="hideFlexAlignment"
    :disable-constrain="hideFlexAlignment"
  ></flex-align>

  <cvt-collapse
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
        v-if="genAiEnabled"
        title="Generative AI"
        name="GenerativeContent"
        :special-style="isSpecialStyle"
    >
      <GenAIOptions :mode="mode" />
    </cvt-collapse-item>
    <cvt-collapse-item
      :title="$t('elements.properties.style.label')"
      name="style"
      :initial-active="true"
      :special-style="isSpecialStyle"
    >
    <div class="row">
      <div class="col-auto">
        <h6>
          {{ $t('elements.properties.shadow.normal') }}
        </h6>
      </div>
      <div class="col-12">
        <cvt-radio-group orientation="horizontal" :initial-value="shadow" class="row">
          <cvt-radio-button
            size="md"
            value=""
            class="col-4 mb-2"
            :shape="RadioButtonShape"
            @radioClick="updateShadow"
          >
            {{ $t('elements.properties.option.none') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="text-shadow-basic"
            class="col-4 mb-2"
            :shape="RadioButtonShape"
            @radioClick="updateShadow"
          >
            {{ $t('element.property.button.shadow.basic') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="text-shadow-hard"
            class="col-4 mb-2"
            :shape="RadioButtonShape"
            @radioClick="updateShadow"
          >
            {{ $t('element.property.button.shadow.hard') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="text-shadow-heavy"
            class="col-4 mb-2"
            :shape="RadioButtonShape"
            @radioClick="updateShadow"
          >
            {{ $t('element.property.button.shadow.heavy') }}
          </cvt-radio-button>
          <cvt-radio-button
            size="md"
            value="text-shadow-letterpress"
            class="col-4 mb-2"
            :shape="RadioButtonShape"
            @radioClick="updateShadow"
          >
            {{ $t('element.property.button.shadow.letterpress') }}
          </cvt-radio-button>
        </cvt-radio-group>
      </div>
    </div>
    </cvt-collapse-item>
  </cvt-collapse>

  <cvt-collapse
    v-if="!isGridBuilder"
    :accordion="true"
    :theme="mode === 'LIGHT' ? 'light' : 'dark'"
    :prepend-chevron="prependChevron"
  >
    <cvt-collapse-item
      :title="$t('elements.properties.spacing.label')"
      name="spacing"
      :initial-active="false"
      :special-style="isSpecialStyle"
    >
      <spacing-ctrl />
    </cvt-collapse-item>
  </cvt-collapse>
</div>
