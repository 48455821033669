import { Font, FroalaFormat } from '../../../lib/font'
import {
  froalaLinkDefaultTypography,
  mobileViewTypography,
  themeColors,
  typography,
} from './cssx'
import _ from 'lodash'
import { FroalaLinkTypography, Typography } from './types'
import { Color } from 'color'
import { Style } from 'cssx'
import { FONT_SIZES } from '../../../lib/font'

export function pxToRem({
  px,
  base = 16,
  factor = 1,
}: {
  px: number
  base?: number
  factor?: number
}) {
  let rem = (px / base) * factor
  return `${rem < 1 ? 1 : rem}rem`
}

function exactPxToRem({
  px,
  base = 16,
  factor = 1,
}: {
  px: number
  base?: number
  factor?: number
}) {
  let rem = (px / base) * factor
  return `${rem}rem`
}

export function updateTypographyCSSX(typo: Typography) {
  typography.add(
    _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => f.toStyle())
      .value(),
  )

  typography.add(
    _.chain(typo)
      .mapKeys((v, k) => `.alt-text-color .font-style-${k}`)
      .mapValues((f) => f.getDefaultAltTextColor())
      .value() as Style,
  )

  typography.add({
    '@media (max-width: 768px)': _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => ({
        'font-size': pxToRem({ px: f.size, factor: 0.9 }),
      }))
      .value(),
  })

  typography.add({
    '@media (max-width: 575px)': _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => ({
        'font-size': pxToRem({ px: f.size, factor: 0.8 }),
      }))
      .value(),
  })
  mobileViewTypography.add(
    _.chain(typo)
      .mapKeys((v, k) => `.font-style-${k}`)
      .mapValues((f) => ({
        'font-size': pxToRem({ px: f.size, factor: 0.8 }),
      }))
      .value(),
  )
}

export function updateFrolaLinkTypographyCSSX(
  froalaLinkTypo: FroalaLinkTypography,
) {
  froalaLinkDefaultTypography.add({
    '.draft-editor .fr-element a': {
      color: froalaLinkTypo.link.defaultTextColor,
    },
    '.draft-editor .fr-element a:hover': {
      color: froalaLinkTypo.linkHover.defaultTextColor,
    },
    '.draft-editor .fr-element a:visited': {
      color: froalaLinkTypo.linkVisited.defaultTextColor,
    },
  })
}

export function addFontClassesToSheetCSSX(fonts: Font[]) {
  typography.add(
    _.zipObject(
      fonts.map((f) => f.froalaClassName()),
      fonts.map((f) => f.toStyle()),
    ) as Style,
  )
}

export function addFontSizesToSheetCSSX(
  sizes: { label: string; value: string }[],
) {
  typography.add(
    _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': `${s.value}px`,
      })),
    ) as Style,
  )

  typography.add({
    '@media (max-width: 768px)': _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': pxToRem({ px: parseInt(s.value), factor: 0.9 }),
      })),
    ) as Style,
    '@media (max-width: 575px)': _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': pxToRem({ px: parseInt(s.value), factor: 0.8 }),
      })),
    ) as Style,
  })
  mobileViewTypography.add(
    _.zipObject(
      sizes.map((s) => `.font-size-${s.value}`),
      sizes.map((s) => ({
        'font-size': pxToRem({ px: parseInt(s.value), factor: 0.8 }),
      })),
    ) as Style,
  )
}

export function updateThemeColorsCSSX(colors: { [key: string]: Color }) {
  const rootFonts = FONT_SIZES.map((s) => ({
    [`--font-size-${s.value}`]: exactPxToRem({ px: parseInt(s.value) }),
  })).reduce((acc, curr) => ({ ...acc, ...curr }), {})

  const rootColors = Object.entries(colors)
    .map(([key, value]) => ({
      [`--${key}`]: value.hex(),
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  themeColors.add({
    ':root': {
      ...rootFonts,
      ...rootColors,
    },
    ':root:has(div.page-engine-viewmode--sm)': {
      ...FONT_SIZES.map((s) => ({
        [`--font-size-${s.value}`]: exactPxToRem({
          px: parseInt(s.value),
          factor: 0.8,
        }),
      })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
    },
  })
  for (let id in colors) {
    let color = colors[id]
    themeColors.add({
      [`.font-color-${id}`]: {
        color: color.hex(),
      },
    })
  }
}
