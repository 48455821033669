
<div
  :id="vnodeId"
  ref="draftWrapperRef"
  :data-vnode-id="vnodeId"
  class="cvt-editor-text"
  :class="editorClasses"
  draggable="false"
  @mouseover="target"
  @click="enableEditor"
  @dblclick="resizeContent"
  @contextmenu="(event) => showContextMenuIfNotEditing(event)"
  @dragstart="dragstart"
  @dragend="dragend"
>
  <drag-and-drop-zone :z-index="dropzoneZIndex" @dropped="addElementToSide" />

  <!-- main vdom node -->
  <animated-placeholder :loading="isLoading">
    <div
      v-once
      class="draft-editor"
      :class="stylesheet.classes.cvtListContainer"
    >
      <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
      <froala-text-editor
        ref="frolaWrapper"
        :content="contentsHTMLModel"
        :tag="'div'"
        :config="froalaConfiguration"
        @change="saveWithHtml"
        @contentChanged="resizeContent"
      ></froala-text-editor>
    </div>
  </animated-placeholder>
  <template v-if="showActionButtonsOnClick && !isGridBuilder">
    <!--THis is only here because of freshwork in v1 gridbuilder,
    once they migrate to v2 or v3, it will become irrelevant-->
    <action-buttons
      v-if="selected"
      @duplicate="duplicateAndValidate"
      @remove="removeAndValidate"
    />
  </template>
</div>
