import * as _ from 'lodash'
import * as axios from 'axios'

export default {
  namespaced: true,
  state: {
    config: {}
  },
  actions: {
    async load({ state }, cache = true) {
      if (cache && !_.isEmpty(state.config)) {
        return state.config
      }

      const { data } = await axios.get('/api/config')
      return state.config = data
    }
  }
}
