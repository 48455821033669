<template>
  <!-- Froala Editor component -->
  <component :is="currentTag">
    <slot></slot>
  </component>
</template>

<script>
import FroalaEditor from 'froala-editor'

export default {
  name: 'FroalaWrapper',
  props: {
    tag: {
      type: String,
      default: 'div',
    },
    value: {
      type: String,
      default: '',
    },
    config: {
      type: Object,
      required: false,
      default: null,
    },
    onManualControllerReady: {
      type: Function,
      required: false,
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      initEvents: [],

      // Tag on which the editor is initialized.
      currentTag: 'div',

      // Editor element.
      editor: null, // Changed from _editor to editor to fix linter error

      // Current config.
      currentConfig: null,

      // Editor options config
      defaultConfig: {
        immediateVueModelUpdate: false,
        vueIgnoreAttrs: null,
      },

      editorInitialized: false,

      SPECIAL_TAGS: ['img', 'button', 'input', 'a'],
      INNER_HTML_ATTR: 'innerHTML',
      hasSpecialTag: false,

      model: null,
      oldModel: null,
    }
  },
  watch: {
    value: function () {
      this.model = this.value
      this.updateValue()
    },
  },
  created: function () {
    this.currentTag = this.tag || this.currentTag
    this.model = this.value
  },

  // After first time render.
  mounted: function () {
    if (this.SPECIAL_TAGS.indexOf(this.currentTag) != -1) {
      this.hasSpecialTag = true
    }

    if (this.onManualControllerReady) {
      this.generateManualController()
    } else {
      this.createEditor()
    }
  },

  beforeDestroy: function () {
    // Changed from beforeUnmount to beforeDestroy for Vue 2 compatibility
    this.destroyEditor()
  },
  methods: {
    updateValue: function () {
      if (JSON.stringify(this.oldModel) == JSON.stringify(this.model)) {
        return
      }
      this.setContent()
    },

    createEditor: function () {
      if (this.editorInitialized) {
        return
      }

      this.currentConfig = this.clone(this.config || this.defaultConfig)
      this.currentConfig = { ...this.currentConfig }

      this.setContent(true)

      // Bind editor events.
      this.registerEvents()
      this.initListeners()

      this.editor = new FroalaEditor(this.$el, this.currentConfig)

      this.editorInitialized = true
    },

    // Return clone object
    clone: function (item) {
      const me = this
      if (!item) {
        return item
      } // null, undefined values check

      let types = [Number, String, Boolean],
        result

      // normalizing primitives if someone did new String('aaa'), or new Number('444');
      types.forEach(function (type) {
        if (item instanceof type) {
          result = type(item)
        }
      })

      if (typeof result == 'undefined') {
        if (Object.prototype.toString.call(item) === '[object Array]') {
          result = []
          item.forEach(function (child, index, array) {
            result[index] = me.clone(child)
          })
        } else if (typeof item == 'object') {
          // testing that this is DOM
          if (item.nodeType && typeof item.cloneNode == 'function') {
            result = item.cloneNode(true)
          } else if (!item.prototype) {
            // check that this is a literal
            if (item instanceof Date) {
              result = new Date(item)
            } else {
              // it is an object literal
              result = {}
              for (var i in item) {
                result[i] = me.clone(item[i])
              }
            }
          } else {
            // Removed the constant condition
            if (item.constructor) {
              result = new item.constructor()
            } else {
              result = item
            }
          }
        } else {
          result = item
        }
      }
      return result
    },

    setContent: function (firstTime) {
      if (!this.editorInitialized && !firstTime) {
        return
      }

      if (this.model || this.model == '') {
        this.oldModel = this.model

        if (this.hasSpecialTag) {
          this.setSpecialTagContent()
        } else {
          this.setNormalTagContent(firstTime)
        }
      }
    },

    setNormalTagContent: function (firstTime) {
      var self = this

      function htmlSet() {
        self.editor.html.set(self.model || '')

        //This will reset the undo stack everytime the model changes externally. Can we fix this?
        self.editor.undo.saveStep()
        self.editor.undo.reset()
      }

      if (firstTime) {
        this.registerEvent('initialized', function () {
          htmlSet()
        })
      } else {
        htmlSet()
      }
    },

    setSpecialTagContent: function () {
      var tags = this.model

      // add tags on element
      if (tags) {
        for (var attr in tags) {
          if (
            Object.prototype.hasOwnProperty.call(tags, attr) &&
            attr != this.INNER_HTML_ATTR
          ) {
            this.$el.setAttribute(attr, tags[attr])
          }
        }

        if (Object.prototype.hasOwnProperty.call(tags, this.INNER_HTML_ATTR)) {
          this.$el.innerHTML = tags[this.INNER_HTML_ATTR]
        }
      }
    },

    destroyEditor: function () {
      if (this.editor) {
        this.editor.destroy()
        this.editorInitialized = false
        this.editor = null
      }
    },

    getEditor: function () {
      return this.editor
    },

    generateManualController: function () {
      var controls = {
        initialize: this.createEditor,
        destroy: this.destroyEditor,
        getEditor: this.getEditor,
      }
      this.onManualControllerReady(controls)
    },

    updateModel: function () {
      var modelContent = ''
      if (this.hasSpecialTag) {
        var attributeNodes = this.$el.attributes
        var attrs = {}

        for (var i = 0; i < attributeNodes.length; i++) {
          var attrName = attributeNodes[i].name
          if (
            this.currentConfig.vueIgnoreAttrs &&
            this.currentConfig.vueIgnoreAttrs.indexOf(attrName) != -1
          ) {
            continue
          }
          attrs[attrName] = attributeNodes[i].value
        }

        if (this.$el.innerHTML) {
          attrs[this.INNER_HTML_ATTR] = this.$el.innerHTML
        }

        modelContent = attrs
      } else {
        var returnedHtml = this.editor.html.get()
        if (typeof returnedHtml === 'string') {
          modelContent = returnedHtml
        }
      }

      this.oldModel = modelContent
      this.$emit('update:value', modelContent)
    },

    initListeners: function () {
      var self = this

      this.registerEvent('initialized', function () {
        if (self.editor.events) {
          // bind contentChange and keyup event to froalaModel
          self.editor.events.on('contentChanged', function () {
            self.updateModel()
          })

          if (self.currentConfig.immediateVueModelUpdate) {
            self.editor.events.on('keyup', function () {
              self.updateModel()
            })
          }
        }
      })
    },

    // register event on editor element
    registerEvent: function (eventName, callback) {
      if (!eventName || !callback) {
        return
      }

      // Initialized event.
      if (eventName == 'initialized') {
        this.initEvents.push(callback)
      } else {
        if (!this.currentConfig.events) {
          this.currentConfig.events = {}
        }

        this.currentConfig.events[eventName] = callback
      }
    },

    registerEvents: function () {
      // Handle initialized on its own.
      this.registerInitialized()

      // Get current events.
      var events = this.currentConfig.events

      if (!events) {
        return
      }

      for (var event in events) {
        if (
          Object.prototype.hasOwnProperty.call(events, event) &&
          event != 'initialized'
        ) {
          this.registerEvent(event, events[event])
        }
      }
    },

    registerInitialized: function () {
      // Bind initialized.
      if (!this.currentConfig.events) {
        this.currentConfig.events = {}
      }

      // Set original initialized event.
      if (this.currentConfig.events.initialized) {
        this.registerEvent('initialized', this.currentConfig.events.initialized)
      }

      // Bind initialized event.
      this.currentConfig.events.initialized = () => {
        for (var i = 0; i < this.initEvents.length; i++) {
          this.initEvents[i].call(this.editor)
        }
      }
    },
  },
}
</script>
