<template>
  <div class="froala-root">
    <!-- Any tag modification will change how froala data is saved. See saveWithHtml method -->
    <!-- eslint-disable vue/no-v-html -->
    <div class="froala-editor" v-html="content" />
  </div>
</template>

<script>
import FroalaEditor from 'froala-editor/js/froala_editor.min'
import {
  cleanFroalaContent,
  FroalaAfterCommand,
} from '@/js/components/common/froala-after-command'

export default {
  name: 'FroalaTextEditor',
  props: {
    content: {
      type: String,
      required: true,
    },
    config: { type: Object, required: true },
    tag: { type: String, default: 'div' },
    saveInterval: { type: Number, default: 1000 },
  },
  data() {
    return {
      editor: null,
      currentConfig: null,
      editorCreated: false,
    }
  },
  mounted() {
    this.createEditor()
  },
  destroyed() {
    this.shutdown()
  },
  methods: {
    shutdown() {
      if (this.$el) {
        this.editor.destroy()
      }
    },
    createEditor() {
      if (this.editorCreated) return

      const currentConfig = Object.assign({}, this.config)

      const defaultConfig = {
        ...currentConfig,
        saveInterval: 1000,
        // Set the save param.
        saveParam: 'content',
        // Set the save URL.
        saveURL: '#',
        // HTTP request type.
        saveMethod: 'POST',
        events: {
          ...(currentConfig.events || {}),
          'save.before': (html) => {
            this.saveWithHtml(html)
            return false
          },
          contentChanged: () => {
            this.$emit('contentChanged', this.content)
          },
          'commands.after': function (cmd, param1, param2) {
            FroalaAfterCommand(this, cmd, param1, param2, [])
            this.save.save()
          },
        },
      }
      this.editor = new FroalaEditor(this.$el, defaultConfig)
      this.editorCreated = true
    },
    saveWithHtml(html) {
      let htmlStr = cleanFroalaContent(html)
      this.$emit('change', htmlStr)
    },
  },
}
</script>

<style scoped lang="scss">
</style>
